/* eslint-disable complexity */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Dialog, DialogTitle, IconButton, Modal } from '@mui/material';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { fetchData, putAPI, getActivityView, deleteActivity } from '../api/api';
import {
	Row, Col, List, Input, Button,
	Switch, Checkbox, Tooltip, message, Modal as Amodal, DatePicker,
} from 'antd';
import { Backdrop, CircularProgress } from '@mui/material';
import { UplodForm } from './upload';
import { useNavigate, useLocation } from 'react-router-dom';
import { uniqBy, debounce, get, startCase, camelCase } from 'lodash';
import { getPath, safeJSONParse } from '../shared/formatting/index';
import {
	CloseOutlined, PlusOutlined, RedoOutlined, FileSyncOutlined,
	TableOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TableComp from '../components/table/table';
import { columnDef } from '../shared/defined-columns/profile-tracker';
import CardComponent from './card-component';
import { getPermission } from '../shared/permissions';
import { ExportToExcel } from '../shared/components/excel/export-to-excel';
import { StatComponent } from '../stat-component';
import _ from 'lodash';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import './index.css';
import LaneData from './lane-data';
import moment from 'moment';
import { exceedLeaves } from '../shared/constant-value';
type FormCreatorProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>

}

export const ProfileTracker = ({
	setFullScreen,
}: FormCreatorProps) => {
	const [uid, setUid] = useState(0);
	const [viewResponseLoading, setViewResponseLoading]: any = useState(true);
	const [viewResponseData, setViewResponseData]: any = useState([]);
	const [permissionViewList, setPermissionViewList]: any = useState([]);
	const [profileTrackerData, setProfileTracker]: any = useState([]);
	const [filterOptions, setFilterOptions]: any = useState([]);
	const [open, setOpen] = useState(false);
	const [filteredArray, setFilterArray]: any = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [queryString, setQueryString] = useState('');
	const [loading, setLoading] = useState(false);
	const [utils, setUtils] = useState({});
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname } = location;
	const path = getPath(pathname);
	const isLeaveTracker = path === 'leave-tracker';
	const [view, setView]
		= useState(false);
	const [selectedFilters, setSelectedFilters]: any = useState(
		window.localStorage.getItem(path || '')
			? window.localStorage.getItem(path || '')?.split(',') : []);
	const [toggleView, setToggleView] = useState(0);
	const [laneView, setLaneView] = useState(false);

	const permissions = getPermission('UsersList');
	const { is_admin, is_update } = permissions;

	const [statData, setStataData] = useState([]);
	const [statFilters, setStatFilters] = useState({});
	const [graphQuery, setGraphQuery]
		= useState(get(statFilters, 'defaultvalue', ''));

	const userFilterId
		= is_admin ? '' : get(localStorage, 'userLoginId', '');
	const [visible, setVisible] = useState(false);

	const [laneInfo, setLaneInfo] = useState([{ name: '', items: [] }]);

	const [startuid, setStartUid] = useState('');

	const [actionid, setAactionid] = useState('');
	const [response, setResponse] = useState('');
	const [durations, setDurations] = useState('');

	const [showTableModal, setShowTableModal] = useState(false);
	const [exceedLeaveData, setExceedLeaveData] = useState([]);
	const [filterLeaveData, setFilterLeaveData] = useState([]);
	const [exceedValue, setExceedValue] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [monthCounts, setMonthCounts] = useState([]);
	// Cconst profileDisplay = ProfileDisplay.submitResponse;
	const showModal = async (uid:any, actionId:any, response:any): Promise<void> => {
		setStartUid(uid);
		setAactionid(actionId);
		setResponse(response);

		const payload = {uid: uid, action_id: actionId, isActionUpdate: 'true', userId: localStorage.getItem('user'), created_by: localStorage.getItem('userLoginId')};
		await putAPI(`/${path}/processWorkflow`, payload);

		setShowTableModal(true);
	};

	const handleOk = async (): Promise<void> => {
		const currentDate = new Date();
		let activityid = '';
		getActivityView(`/${path}/getActivity/${startuid}`).then((res: any) => {
			res.data.forEach((item: any) => {
				if (item.responseId === null && item.sourceType === null) {
					activityid = get(item, 'activityId');
				}
			});

			const payload = {uid: startuid, actionId: actionid, response: response, activityId: activityid, durations, last_start_date: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'), created_by: localStorage.getItem('user'), userId: localStorage.getItem('userLoginId')};
			putAPI(`/${path}/processWorkflow`, payload);
		});
		setShowTableModal(false);
	};

	const handleCancel = (): void => {
		let aid = '';
		getActivityView(`/${path}/getActivity/${startuid}`).then((res: any) => {
			res.data.forEach((item: any) => {
				if (item.responseId === null && item.sourceType === null) {
					aid = get(item, 'activityId');
				}
			});
			deleteActivity(`/${path}/delActivity`, {activityId: aid});
		});
		setShowTableModal(false);
	};

	useEffect(() => {
		if (!graphQuery) {
			setGraphQuery(get(statFilters, 'defaultvalue', ''));
		}
	}, [statFilters]);

	const filter = (profileTrackerResponse: any, filters: any) =>
		filters.length ? profileTrackerResponse.filter(
			(item: { status: string }) =>
				filters.includes(item.status)) : profileTrackerData;

	const columns = Object.keys(statData[0] || []).reverse().map(key => ({
		title: _.startCase(key),
		dataIndex: key,
		key,
		align: 'center',
	}));

	const returnLink = (dateRange = {}) => {
		if (_.isEmpty(dateRange)) {
			return `/${path}?qsPath=${queryString}&&uid=${userFilterId}`;
		}

		const monthyear = _.get(dateRange, 'date', '');
		return `/${path}?qsPath=${queryString}&&uid=${userFilterId}&monthYear=${monthyear}`;
	};

	const fetch = async (dateRange = {}): Promise<void> => {
		setLoading(true);
		const leaveCounts = (is_admin && is_update) ? await fetchAllLeaveCounts() : '';
		if (isLeaveTracker) {
			getMonthLeaveCounts();
		}

		return fetchData(returnLink(dateRange))
			.then((res: any) => {
				let profileTrackerResponse = res.data;
				setFilterArray(res.data);
				const filteredOptions: any = res.statusCount.map(
					(item: any) =>
						({
							label: item.status, value: item.status,
							count: item.count,
						}));
				if (isLeaveTracker && (is_admin && is_update)) {
					setFilterLeaveData(leaveCounts.data);
					filteredOptions.push(exceedLeaves);
					const exceededOption = filteredOptions.find((option: any) => option.value === 'exceeded');
					exceededOption.count = leaveCounts.rowCount;
				}

				const getFilterValues = uniqBy(filteredOptions, 'value');
				setFilterOptions(getFilterValues);
				const filterExists = getFilterValues.some((item: any) =>
					selectedFilters.includes(item.value));
				if (filterExists) {
					profileTrackerResponse
						= filter(profileTrackerResponse, selectedFilters);
				} else {
					localStorage.removeItem(path || '');
					setSelectedFilters([]);
				}

				setViewResponseData(profileTrackerResponse);
				setPermissionViewList(res.permissionList);
				setProfileTracker(res.data);
				setViewResponseLoading(false);
				setLoading(false);
			})
			.catch(() => {
				setViewResponseLoading(false);
				setLoading(false);
			});
	};

	const graphChange = (e: string) => {
		setGraphQuery(e);
	};

	const getAppDetails = async () => {
		try {
			const response = await fetchData(`/apps/app-detail/?path=${path}`);
			const result = response.data;
			if (result && result.length) {
				setUtils(safeJSONParse(get(result, '[0].utils', {})));
				setStatFilters(JSON.parse(get(result, '[0]statFilters')));
			}
		} catch (error: any) {
			message.error(error.message);
		}
	};

	const fetchAuthUsers = async () => {
		setLoading(true);
		try {
			const resp = await fetchData(`/${path}/auth-users`);
			await resp.json();
			fetch();
		} catch {
			fetch();
			setLoading(false);
		}
	};

	const fetchAllLeaveCounts = async () => {
		try {
			const resp = await fetchData(`/${path}/get-all-leave-count`);
			return resp;
		} catch (error: any) {
			message.error(error.message);
		}
	};

	const fetchMonthLeaveCounts = async (monthYear = '', value = '') => {
		try {
			const resp = await fetchData(`/${path}/get-month-leave-count?name=${value}&&monthYear=${monthYear}&&uid=${userFilterId}`);
			return resp;
		} catch (error: any) {
			message.error(error.message);
		}
	};

	const fetchStatData = async () => {
		try {
			const resp
				= await fetchData(`/${path}/getStat?status=${graphQuery}`);
			const result = resp.data || [];
			result.forEach((item: any) => {
				for (const key in item) {
					if (!item[key]) {
						item[key] = 0;
					}
				}
			});
			setStataData(result);
		} catch {
			fetch();
		}
	};

	const fetchForm = async () => {
		try {
			const resp = await fetchData(`/forms/${path}`);
			setToggleView(get(resp, 'results.toggleView', 0));
			const uid = get(resp, 'results.uid');
			const laneViewResponse = await fetchData(`/laneview/getLaneData?uid=${uid}`);
			const flag = Boolean(parseInt(get(resp, 'results.toggleView'), 10));

			// Check if laneViewResponse.results exists and contains the expected data
			if (laneViewResponse?.results) {
				setLaneView(laneViewResponse?.results?.attributes?.length > 0);
			}

			setView(flag);
			setUid(resp.results.uid);
		} catch (error) {
			console.error('Error in fetchForm:', error);
			setLoading(false);
		}
	};

	const handleLane = () => {
		setVisible(true);
		fetchData(`/laneview/getLaneData?uid=${uid}`)
			.then(res => {
				const info = JSON.parse(res.results.attributes);
				setLaneInfo(info);
			})
			.catch(() => console.log('error'));
	};

	useEffect(() => {
		document.title = startCase(
			String(pathname.replace('-', ' ')));
		setFullScreen(true);
		fetchForm();
		getAppDetails();
	}, [path]);

	useEffect(() => {
		fetchStatData();
	}, [graphQuery]);

	useEffect(() => {
		fetch();
	}, [view, userFilterId, setViewResponseData, viewResponseLoading]);

	const toggleModal = (refresh = false): void => {
		setOpen(!open);
		if (refresh) {
			fetch();
		}
	};

	// This reloads the page
	const handleRefresh = () => {
		window.location.reload();
	};

	const toggleModalWithFetch = (): void => {
		setOpen(!open);
		fetch();
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, searchType: 'asset' | 'general'): void => {
		const { value } = e.target;
		if (searchType === 'general') {
			setSearchQuery(value);
		} else if (searchType === 'asset') {
			setSearchTerm(value);
		}

		if (isLeaveTracker) {
			getMonthLeaveCounts('', value);
		}

		const call = debounce(() => {
			if (searchType === 'general') {
				const query = camelCase(get(utils, 'query'));
				const dataToFilter = exceedValue === 'exceeded' ? exceedLeaveData : viewResponseData;

				const filteredData = value
					? dataToFilter.filter((item: any) =>
						String(item[query]).toLowerCase().includes(String(value).toLowerCase()),
					)
					: exceedValue === 'exceeded' ? filterLeaveData : filteredArray;

				if (exceedValue === 'exceeded') {
					setExceedLeaveData(filteredData);
				} else {
					setViewResponseData(filteredData);
				}
			} else if (searchType === 'asset') {
				const filteredAssets = value
					? viewResponseData.filter((data: any) =>
						data.assignedTo.toLowerCase().includes(value.toLowerCase()),
					)
					: filteredArray;

				setViewResponseData(filteredAssets);
			}
		}, 900);

		call();
	};

	const switchView = (value: boolean) => {
		setSearchQuery('');
		if (value) {
			setQueryString('');
		} else {
			setQueryString('user-profile-vw');
		}

		setView(value);
	};

	const onCheckboxChange = async (e: CheckboxChangeEvent) => {
		const { value, checked } = e.target;

		if (checked && !selectedFilters.includes(value)) {
			const tempArray: any = [...selectedFilters, value];
			setSelectedFilters(tempArray);
			window.localStorage.setItem(path || '', tempArray.join(','));

			if (value === 'exceeded') {
				setExceedValue(value);
				const leaveCounts = await fetchAllLeaveCounts();
				setExceedLeaveData(leaveCounts.data);
				setFilterLeaveData(leaveCounts.data);
			} else {
				const profileTrackerResponse = filter(profileTrackerData, tempArray);
				setViewResponseData(profileTrackerResponse);
				setFilterArray(profileTrackerResponse);
			}
		} else {
			const updatedFilters = selectedFilters.filter((item: any) => item !== value);
			setSelectedFilters(updatedFilters);
			window.localStorage.setItem(path || '', updatedFilters.join(','));

			if (value === 'exceeded') {
				setExceedLeaveData([]);
				setFilterLeaveData([]);
				setExceedValue('');
			} else {
				const profileTrackerResponse = filter(profileTrackerData, updatedFilters);
				setViewResponseData(profileTrackerResponse);
				setFilterArray(profileTrackerResponse);
			}
		}
	};

	const handleRedirect = (data: any) => navigate(`/${path}/${data.uid}`);
	if (loading) {
		return <Backdrop
			sx={
				{
					color: '#fff', height: '100vh',
					zIndex: theme => theme.zIndex.drawer + 1,
				}
			}
			open={loading}>
			<CircularProgress color='inherit' />
		</Backdrop>;
	}

	const handleDatePicker = async (e: any) => {
		setSelectedDate(e);
		const $d = get(e, '$d', '');
		// eslint-disable-next-line no-negated-condition
		if ($d !== '') {
			const month = moment($d).month() + 1;
			const year = moment($d).year();
			const monthYear = `${month}-${year}`;
			await fetch({ date: monthYear });
			if (isLeaveTracker) {
				await getMonthLeaveCounts(monthYear);
			}
		} else {
			fetch();
		}
	};

	const getMonthLeaveCounts = async (monthYear = '', value = '') => {
		const monthLeaveCounts = (isLeaveTracker)
			? await fetchMonthLeaveCounts(monthYear, value)
			: '';
		setMonthCounts(monthLeaveCounts?.data || []);
	};

	return (
		<div>
			{laneView}
			<Row className='main-container'>
				<Col md={5} xs={24} sm={24} className='filter-col'>
					<Row style={{
						backgroundColor: '#fff', padding: '7px',
						alignItems: 'center', gap: '20%',
					}}>
						<Col span={12} >Filters</Col>
					</Row>
					<Row style={{ minHeight: '90px' }}>
						{selectedFilters.length
							? <div className='filter-options'>
								{selectedFilters.map((item: any, index: number) => (
									<p key={index} >
										{item}</p>
								))}
							</div> : ''}
					</Row>
					<Row >
						<Col span={24} style={{ marginBottom: '10px' }}>
							Filter by Status</Col>
						<Col span={24}>
							{filterOptions.map((item: any, index: number) => (
								item.count
									? <Col key={index} style={{ paddingBottom: '5px' }}>
										<Row style={{ justifyContent: 'space-between' }}>
											<Checkbox value={get(item, 'value', '')}
												checked={selectedFilters.includes(
													get(item, 'value', ''))}
												onChange={onCheckboxChange}>
												{get(item, 'label', '')}
											</Checkbox>
											<p style={{ color: 'rgb(22, 119, 255)' }}>
												{item.count}</p>
										</Row>
									</Col> : ''
							))}
						</Col>
					</Row>
				</Col>
				<Col md={19} xs={24} sm={24}>
					<Row style={{
						backgroundColor: '#fff', padding: '10px',
						display: 'flex',
						justifyContent: 'space-between',
					}}>
						<Col md={18} sm={12} xs={24} style={{ display: 'flex' }}>
							<Col span={8}>
								<Input.Search allowClear
									value={searchQuery}
									name='search-filter'
									placeholder
										={get(utils, 'placeholder', '')}
									onChange={e => handleSearch(e, 'general')} />
							</Col>
							{path === 'asset-tracker'
								&& (
									<Col span={8} style={{ marginLeft: '10px' }}>
										<Input.Search
											allowClear
											value={searchTerm}
											name='search-filter'
											placeholder='Search by Name'
											onChange={e => handleSearch(e, 'asset')}
										/>
									</Col>
								)}
							{exceedValue === 'exceeded' ? ''
								: <Col span={12} style={{ textAlign: 'start', marginLeft: '10px' }}>
									<DatePicker
										style={{ width: '50%' }}
										picker='month'
										onChange={handleDatePicker}
										value={selectedDate}
									/>
								</Col>}
						</Col>
						<Col md={6} sm={11} xs={24}>
							<Row style={{
								justifyContent: 'end',
								alignItems: 'center', gap: '10px',
								paddingTop: '5px',
							}}>
								{laneView ? <Col> {/* Lane View */}
									<Tooltip title='Lane view'>
										<Button
											type='primary'
											shape='circle'
											icon={<SignalCellularAltIcon />} onClick={handleLane} />
									</Tooltip>
								</Col>
									: ''}
								{toggleView
									? <Col>
										<Tooltip placement='topLeft'
											title='Click to Switch the view'>
											<Switch checked={view}
												onChange={switchView}
												checkedChildren
													={<UnorderedListOutlined />}
												unCheckedChildren
													={<TableOutlined />} />
										</Tooltip>
									</Col>
									: ''}
								{exceedValue === 'exceeded' ? ''
									: <Col>
										<Tooltip title='Create new'>
											<Button
												type='primary'
												shape='circle'
												onClick={() => toggleModal()}
												icon={<PlusOutlined />} />
										</Tooltip>
									</Col>}
								<Col>
									<Tooltip title='Sync data'>
										{is_update && path !== 'profile-tracker'
											? <Button
												type='primary'
												shape='circle'
												onClick={fetchAuthUsers}
												icon={<FileSyncOutlined />} /> : ''}
									</Tooltip>
								</Col>
								<Col>
									<Tooltip title='Download'>
										<span>
											<ExportToExcel apiData={exceedValue === 'exceeded' ? exceedLeaveData : monthCounts}
												fileName={String(exceedValue === 'exceeded' ? selectedFilters : path)} />
										</span>
									</Tooltip>
								</Col>
								<Col>
									<Tooltip title='Refresh'>
										<Button
											type='primary'
											shape='circle'
											onClick={() =>
												handleRefresh()}
											icon={<RedoOutlined />} />
									</Tooltip>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className=''>
						<Col span={24}>
							{viewResponseLoading ? null
								: view
									? <div>
										<List
											grid={{
												gutter: 16,
												xs: 1,
												sm: 2,
												md: 3,
												lg: 3,
												xl: 4,
												xxl: 4,
											}}
											itemLayout='vertical'
											size='large'
											pagination={{
												pageSize: 16,
											}}
											dataSource={viewResponseData}
											renderItem={(item): any => {
												const result: any = item;
												return (<List.Item
													style={{
														cursor: 'pointer',
														padding: '16px 0',
													}}
													onClick={() =>
														handleRedirect(result)}>
													<CardComponent result={result}
														path={path} />
												</List.Item>);
											}}
										>
										</List>
									</div>
									: <Row>
										<TableComp
											size='small'
											columnDef={exceedValue === 'exceeded' ? get(columnDef(showModal), 'exceeded')(exceedLeaveData)
												: get(columnDef(showModal), `${path}`)(viewResponseData)}
											data={exceedValue === 'exceeded' ? exceedLeaveData : viewResponseData} />
									</Row>
							}

						</Col>
					</Row>

					<br />
					{is_update
						&& permissionViewList && isLeaveTracker
						? <Row><div style={{margin: '10px', fontSize: '20px'}}><b>Approval Pending Queue</b></div>
							<TableComp
								size='small'
								columnDef={get(columnDef(showModal), `${path}`)(viewResponseData)}
								data={permissionViewList} /> </Row>
						: ''}
					{view && is_admin
						? ''
						: statData && statData.length
							? <Row>
								<Col span={24}><StatComponent
									graphChange={graphChange}
									graphQuery={graphQuery}
									statFilters={statFilters}
									statData={statData}
									columns={columns} />
								</Col>
							</Row> : ''

					}
					<Amodal
						title='How long you want the service to run?'
						open={showTableModal} onOk={handleOk} onCancel={handleCancel}
						okText='Submit'
						closable={false}
					>
						<div style={{marginTop: '25px'}}>
							<label htmlFor='start' style={{marginRight: '10px'}} >Duration in minutes</label>
							<input type='number' onChange={e => {
								setDurations(e.target.value);
							}} style={{borderWidth: 'thin'}}></input>
						</div>

					</Amodal>
					{/* model here */}
					<Modal
						open={open}
						onClose={() => toggleModal()}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							overflowY: 'auto',
						}}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box sx={{
							width: '60%',
							height: 'fit-content',
							background: 'white',
							backgroundColor: '#fff',
							boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
							maxHeight: '80vh',
							overflowY: 'auto',
						}}>
							<UplodForm
								onClose={toggleModal}
								toggleModalWithFetch={
									() => toggleModalWithFetch()}></UplodForm>
						</Box>
					</Modal>
				</Col>
			</Row>
			<Dialog open={visible} PaperProps={{ sx: { width: '100%', height: '100%', maxWidth: '80%' } }}>
				<DialogTitle variant='h5' sx={{ display: 'flex', justifyContent: 'space-between' }}><>Lane View</>
					<IconButton aria-label='close' sx={{ color: theme => theme.palette.grey[500] }} onClick={() => {
						setVisible(false);
						fetchAuthUsers();
					}
					}><CloseOutlined /></IconButton></DialogTitle>
				<LaneData laneInfo={laneInfo} profileTrackerData={profileTrackerData} path={path}/>
			</Dialog>
		</div>

	);
};
